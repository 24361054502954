<template>
  <button @click="copyLink"
          class="flex items-center justify-between flex-grow py-3 pl-6 pr-3 border rounded-full group"
          :class="{
            'text-gray-600 bg-white border-gray-600 focus:outline-red-200 focus:outline outline-[3px]': type === 'light',
            'text-white border-white bg-transparent outline-none': type === 'dark',
          }"
  >
    <span class="my-1 mr-4">Copy link</span>
    <div class="relative overflow-hidden transition-colors duration-300 rounded-full group-hover:bg-transparent p-1.5 flex-shrink-0"
         :class="{
           'bg-gray-600': type === 'light',
           'bg-white': type === 'dark',
         }"
    >
      <div class="absolute inset-0 transition-opacity duration-300 rounded-full opacity-0 group-hover:opacity-100 bg-pink-blue" />

      <div class="relative w-5 h-5"
           :class="{
             'text-white': type === 'light',
             'text-gray-600 group-hover:text-white duration-100': type === 'dark',
           }"
      >
        <Presence>
          <Motion :key="copied"
                  :initial="{ opacity: 0, x: 50 }"
                  exit-before-enter="true"
                  :animate="{
                    opacity: 1,
                    x: 0,
                    transition: { delay: 0.1 }
                  }"
                  :exit="{ opacity: 0, x: -50 }"
                  class="absolute w-full h-full"
          >
            <ButtonBaseIcon v-if="copied === false"
                            href-type="copy"
                            class="w-5 h-5"
            />

            <SvgAnimatedCheck v-if="copied === true"
                              class="w-5 h-5"
            />
          </Motion>
        </Presence>
      </div>
    </div>
  </button>
</template>

<script setup lang="ts">
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Motion, Presence } from '@motionone/vue/dist/motion-vue.ssr.js';
import { ref } from 'vue';

type CopyIconProps = {
    url: string;
    type: 'light' | 'dark';
}

const copied = ref(false);
const props = defineProps<CopyIconProps>();

function copyLink() {
  copied.value = true;
  navigator.clipboard.writeText(props.url);

  setTimeout(() => {
    copied.value = false;
  }, 2000);
}
</script>